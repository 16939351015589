import classes from "./Sidebar.module.css";
import { Link } from "react-router-dom";

const SidebarItem = ({ clicked, icon, label, active, path }) => {
  return (
    <li className="text-xs">
      <Link
        to={path}
        onClick={clicked}
        className={classes.SidebarItem}
        data-active={active}
        // style={{ fontSize: "10px" }}
      >
        {icon} <p className="text-xs ml-2 uppercase">{label}</p>
      </Link>
    </li>
  );
};

export default SidebarItem;
