import React, { useState, useMemo } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { UpdatedRequestTable } from "../../components/Table/RequestTable";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../../api/manageuser";
import toast from "react-hot-toast";
import VerticalMenu from "../../../../components/global/VerticalMenu";
import Search from "../../components/Search/Search";
import Pagination from "../../components/Pagination/Pagination";
import Card from "../../components/Card/Card";

const ManageUsers = () => {
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isDeleted, setIsDeleted] = useState(false);

  const handleIsDeleted = () => {
    setIsDeleted(!isDeleted);
  };

  const { mutateAsync: VerifyAccount } = useMutation(api.verifyAccount);
  const { mutateAsync: DeleteAccount } = useMutation(api.deleteAccount);
  const { mutateAsync: SuspendAccount } = useMutation(api.suspendAccount);

  const { data, isLoading, isError, refetch } = useQuery(
    ["Users", { search, isDeleted, currentPage, perPage }],
    ({ queryKey }) => {
      return api.getUsers(queryKey[1]);
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data?.data }
  );

  const { data: summary } = useQuery(
    ["Summary", { search, isDeleted }],
    () => {
      return api.getEmailVerificationSummary();
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data?.data }
  );

  const { data: email } = useQuery(
    ["Email", { search, isDeleted }],
    () => {
      return api.getEmailVerificationData();
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data?.data }
  );

  // console.log(email);

  // console.log(summary);

  let rowOptions = [
    {
      name: "Verify Account",
      action: async (account) => {
        toast.loading("Processing...");
        const payload = {
          email: account?.email,
          type: "otp",
          otp: account?.email_verification_otp,
          is_site_admin: true,
        };
        try {
          const data = await VerifyAccount({ payload });
          // console.log(data);
          toast.remove();
          toast.success(data?.message);
          setTimeout(() => {
            refetch();
          }, 1000);
        } catch ({ response }) {
          console.log(response);
          toast.remove();
          toast.error(response?.data?.message);
        }
      },
    },
    {
      name: "Suspend Account",
      action: async (account) => {
        if (confirm("Are you sure you want to suspend this user?")) {
          toast.loading("Processing...");
          try {
            const data = await SuspendAccount({ id: account?.id });
            // console.log(data);
            toast.remove();
            toast.success(data?.message);
            setTimeout(() => {
              refetch();
            }, 1000);
          } catch ({ response }) {
            toast.remove();
            toast.error(response?.data?.message);
          }
        }
      },
    },
    {
      name: "Delete",
      action: async (account) => {
        if (confirm("Are you sure you want to delete this user?")) {
          toast.loading("Processing...");
          try {
            const data = await DeleteAccount({ id: account?.id });
            // console.log(data);
            toast.remove();
            toast.success(data?.message || "Deleted successfully...");
            setTimeout(() => {
              refetch();
            }, 1000);
          } catch ({ response }) {
            toast.remove();
            toast.error(response?.data?.message);
          }
        }
      },
    },
  ];

  // console.log(data);

  // console.log(isDeleted);

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((data?.current_page || 1) - 1) * (data?.per_page || perPage),
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
      },
      {
        title: "E-mail",
        selector: "email",
        key: "email",
      },
      {
        title: "Designation",
        selector: "designation",
        key: "designation",
        render: ({ item }) => {
          return <span>{item?.name}</span>;
        },
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => {
          return <span className="capitalize">{item}</span>;
        },
      },
      {
        title: "Actions",
        selector: null,
        key: "actions",
        render: ({ row }) => {
          return (
            <VerticalMenu isBackground>
              <div>
                {rowOptions?.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      option?.action(row);
                    }}
                    className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                  >
                    {option?.name}
                  </button>
                ))}
              </div>
            </VerticalMenu>
          );
        },
      },
    ],
    [data?.current_page, data?.per_page, perPage]
  );

  const tableProps = { isLoading, data: data?.data, columns };

  const Summary = ({ bodyText }) => {
    return (
      <div className="w-full flex items-center justify-center gap-[100px] p-1">
        <Card
          title={"VERIFIED ACCOUNTS"}
          body={summary?.number_of_verified_accounts}
        />
        <Card
          title={"UN-VERIFIED ACCOUNTS"}
          body={summary?.number_of_unverified_accounts}
        />
      </div>
    );
  };

  return (
    <PageLayout
      summary={<Summary />}
      title={"Manage Users"}
      isError={isError}
      errorText={"users"}
    >
      <div className="flex items-center justify-between w-full mb-3">
        <Search setSearch={setSearch} setCurrentPage={setCurrentPage} />
        <div className="flex items-center justify-end gap-2">
          <input
            id="is_deleted"
            checked={isDeleted}
            onChange={handleIsDeleted}
            className="outline-none cursor-pointer focus:ring-0"
            type="checkbox"
          />
          <label htmlFor="is_deleted" className="cursor-pointer">
            Deleted Accounts Only
          </label>
        </div>
        {/* <Button {...buttonProps} /> */}
      </div>
      <div>
        <UpdatedRequestTable {...tableProps} />
        <Pagination
          data={data}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </PageLayout>
  );
};

export default ManageUsers;
