import createApiClient from "./api";
import fileDownload from "./export";

const client = createApiClient();

const download = fileDownload();

export const api = {
  getExams: ({
    perPage,
    dropdown,
    search,
    currentPage,
    campaignID,
    pipelineID,
    type,
  }) =>
    client
      .get(
        `/admin/exams?dropdown=${dropdown}&per_page=${perPage}&search=${search}&page=${currentPage}&campaign_ids=${campaignID}&pipeline_ids=${pipelineID}&type=${type}`
      )
      .then(({ data }) => data),

  getExamsDropdown: () =>
    client.get(`/admin/exams?dropdown=1`).then(({ data }) => data),

  getExam: (id) => client.get(`/admin/exams/${id}`).then(({ data }) => data),

  updateVenueCode: ({ dateId, outletId }) =>
    client
      .patch(
        `/admin/exams/bookings/dates/${dateId}/outlets/${outletId}/update-venue-code`
      )
      .then(({ data }) => data),

  getCandidates: ({ id, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/exams/${id}/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getCandidateResult: ({ id, user_group_id }) =>
    client
      .get(`/admin/exams/${id}/candidates/${user_group_id}/results`)
      .then(({ data }) => data),

  getResult: ({ id, user_group_id, result_id }) =>
    client
      .get(
        `/admin/exams/${id}/candidates/${user_group_id}/results/${result_id}`
      )
      .then(({ data }) => data),

  getAttendance: ({
    perPage,
    dropdown,
    search,
    currentPage,
    campaignID,
    pipelineID,
    type,
  }) =>
    client
      .get(
        `/admin/exams/attendances/register?dropdown=${dropdown}&per_page=${perPage}&search=${search}&page=${currentPage}&campaign_ids=${campaignID}&pipeline_ids=${pipelineID}&type=${type}`
      )
      .then(({ data }) => data),

  getBookings: ({ perPage, search, currentPage }) =>
    client
      .get(
        `/admin/exams/bookings/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

  unlockOutlet: ({ dateId, outletId, payload }) =>
    client
      .patch(
        `/admin/exams/bookings/dates/${dateId}/outlets/${outletId}`,
        payload
      )
      .then(({ data }) => data),

  getExamBookings: ({ perPage, search, currentPage, examId }) =>
    client
      .get(
        `/admin/exams/bookings/view-outlets/${examId}?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getBookedCandidates: ({
    perPage,
    search,
    currentPage,
    examId,
    outletId,
    dateId,
  }) =>
    client
      .get(
        `/admin/exams/bookings/${examId}/outlets/${outletId}/dates/${dateId}/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

  updateExamBooking: ({ date_id, outlet_id, payload }) =>
    client
      .put(
        `/admin/exams/bookings/dates/${date_id}/outlets/${outlet_id}`,
        payload
      )
      .then(({ data }) => data),

  createExam: (payload) =>
    client.post(`admin/exams`, payload).then(({ data }) => data),

  updateExam: ({ id, payload }) =>
    client.put(`admin/exams/${id}`, payload).then(({ data }) => data),

  deleteExam: (id) =>
    client.delete(`admin/exams/${id}`).then(({ data }) => data),

  toggleExam: ({ examId, payload }) =>
    client
      .post(`admin/exams/${examId}/toggle-exam-lock`, payload)
      .then(({ data }) => data),

  getPipelines: () =>
    client.get(`/admin/pipelines?dropdown=1`).then(({ data }) => data),

  getAssessments: () =>
    client.get(`/admin/assessments?dropdown=1`).then(({ data }) => data),

  getCampaigns: () =>
    client.get(`/admin/campaigns?dropdown=1`).then(({ data }) => data),

  getOutlets: () =>
    client
      .get(`/admin/outlets?dropdown=1&per_page=200`)
      .then(({ data }) => data),

  createBooking: (payload) =>
    client.post(`/admin/exams/bookings`, payload).then(({ data }) => data),

  resetExam: (payload) =>
    client
      .post(`/admin/exams/reset-progress`, { ...payload })
      .then(({ data }) => data),

  getDates: (campaignID) =>
    client.get(`admin/bookings/dates/${campaignID}`).then(({ data }) => data),

  unlockExam: ({ id, payload }) =>
    client.post(`/admin/exams/unlock/${id}`, payload).then(({ data }) => data),

  bookVenue: (payload) =>
    client
      .post(`admin/exams/bookings/book-a-candidate`, payload)
      .then(({ data }) => data),

  getCandidateResponse: ({ examId, candidateId, assessmentId }) =>
    client
      .get(
        `admin/exams/${examId}/candidates/${candidateId}/assessments/${assessmentId}/spool`
      )
      .then(({ data }) => data),

  downloadCandidateResponse: ({ examId, candidateId, assessmentId }) =>
    download
      .get(
        `admin/export-data/question-response?exam_id=${examId}&assessment_id=${assessmentId}&user_group_id=${candidateId}`
      )
      .then(({ data }) => data),

  changeExamGroup: (payload) =>
    client
      .post(`/admin/candidates/update-exam-placement`, payload)
      .then(({ data }) => data),
};
