import React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";

import Applications from "./pages/Applications";
import Exam from "./pages/Exam";
import Booking from "./pages/Booking";
import Billings from "./pages/Billings";

const Downloads = () => {
  const Summary = ({ bodyText }) => {
    return <div className="w-full  p-1"></div>;
  };

  return (
    <PageLayout title={"Downloads"}>
      <div className="py-4">
        <Applications />
        <Billings />
        <Booking />
        <Exam />
      </div>
    </PageLayout>
  );
};

export default Downloads;
